import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import * as Sentry from '@sentry/browser';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

    constructor() {
    }


    handleError(error) {
        if (environment.production) {
            Sentry.captureException(error.originalError || error);
        }
        console.error(error);
    }
}
