import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ModuleRouting} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {GlobalErrorHandlerService} from './shared/error-handler/global-error-handler.service';
import {registerLocaleData} from '@angular/common';
import localedeCH from '@angular/common/locales/de-CH';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpErrorReportingInterceptor} from './shared/http-interceptors/http-error-reporting.interceptor';
import {ToastrModule} from "ngx-toastr";
import * as Sentry from '@sentry/browser';
import {environment} from "../environments/environment";
import {NgProgressModule} from 'ngx-progressbar';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {HttpSuccessToastInterceptor} from './shared/http-interceptors/http-success-toast.interceptor';
import {KeycloakAngularModule, KeycloakOptions, KeycloakService} from "keycloak-angular";
import {AuthGuard} from "./auth.guard";

Sentry.init({
    dsn: "https://2a5eb9c8fdc049bf8d8c9778e900776b@sentry.io/1420761",
    release: environment.VERSION
});

registerLocaleData(localedeCH);

export function configInitializer(keycloak: KeycloakService) {
    return async () => {
        await keycloak.init(environment.keycloak as KeycloakOptions);
    }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        SharedModule.forRoot(),
        ModuleRouting,
        BrowserModule,
        BrowserAnimationsModule,
        NgProgressModule,
        NgProgressHttpModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        KeycloakAngularModule,
    ],
    providers: [
        AuthGuard,
        {provide: LOCALE_ID, useValue: 'de-CH'},
        {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorReportingInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpSuccessToastInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: configInitializer,
            deps: [KeycloakService],
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
