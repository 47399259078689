Object.defineProperty(exports, "__esModule", { value: true });
var AngularDevToolsExtension = /** @class */ (function () {
    function AngularDevToolsExtension(config) {
        this.config = config;
        this.window = window;
        // Angular with NO Ivy
        if (this.window.ng && this.window.ng.probe && this.window.getAllAngularRootElements) {
            var rootElements = this.window.ng.probe(this.window.getAllAngularRootElements()[0]);
            var providers = rootElements.injector.view.root.ngModule._providers;
            this.router = providers.find(function (p) { return p && p.constructor && p.constructor.name === 'Router'; });
            try {
                this.ngZone = rootElements.injector.get(this.window.ng.coreTokens.NgZone);
            }
            catch (e) {
                console.log(e);
            }
            return;
        }
        // Angular with Ivy
        if (this.window.ng && this.window.ng.getInjector && this.window.getAllAngularRootElements &&
            this.config && this.config.router && this.config.ngZone) {
            try {
                var injector = this.window.ng.getInjector(this.window.getAllAngularRootElements()[0]);
                this.router = injector.get(this.config.router);
                this.ngZone = injector.get(this.config.ngZone);
            }
            catch (e) {
                console.log(e);
            }
            return;
        }
    }
    AngularDevToolsExtension.prototype.navigate = function (path) {
        var _this = this;
        if (this.ngZone && this.router) {
            this.runInZone(function () {
                _this.router.navigateByUrl(path);
            });
        }
    };
    AngularDevToolsExtension.prototype.runInZone = function (action) {
        if (this.ngZone) {
            this.ngZone.run(function () {
                action();
            });
        }
    };
    return AngularDevToolsExtension;
}());
exports.AngularDevToolsExtension = AngularDevToolsExtension;
