var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var observable_store_1 = require("@codewithdan/observable-store");
var rxjs_1 = require("rxjs");
var angular_devtools_extension_1 = require("./angular/angular-devtools-extension");
var ReduxDevToolsExtension = /** @class */ (function (_super) {
    __extends(ReduxDevToolsExtension, _super);
    function ReduxDevToolsExtension(config) {
        var _this = _super.call(this, { trackStateHistory: true, logStateChanges: false }) || this;
        _this.config = config;
        _this.window = window;
        _this.require = _this.window.require;
        _this.devtoolsExtension = window['__REDUX_DEVTOOLS_EXTENSION__'];
        _this.isReact = _this.checkIsReact();
        _this.routeTriggeredByDevTools = false;
        return _this;
    }
    ReduxDevToolsExtension.prototype.init = function () {
        var _this = this;
        this.sync();
        this.window.addEventListener('DOMContentLoaded', function () {
            if (_this.checkIsAngular()) {
                _this.angularExtension = new angular_devtools_extension_1.AngularDevToolsExtension(_this.config);
            }
            _this.hookRouter();
        });
        this.connect();
    };
    ReduxDevToolsExtension.prototype.connect = function (config) {
        var _this = this;
        if (this.devtoolsExtension) {
            this.sub = new rxjs_1.Observable(function (subscriber) {
                var connection = _this.devtoolsExtension.connect(config);
                _this.devToolsExtensionConnection = connection;
                connection.init(config);
                connection.subscribe(function (change) { return subscriber.next(change); });
                return connection.unsubscribe;
            })
                .subscribe(function (action) { return _this.processDevToolsAction(action); });
        }
    };
    ReduxDevToolsExtension.prototype.disconnect = function () {
        if (this.devtoolsExtension) {
            this.devtoolsExtension.disconnect();
            if (this.sub) {
                this.sub.unsubscribe();
            }
        }
    };
    ReduxDevToolsExtension.prototype.processDevToolsAction = function (action) {
        // Called as user interacts with Redux Devtools controls
        if (action && action.type === Actions.DISPATCH) {
            switch (action.payload.type) {
                case Actions.JUMP_TO_STATE:
                case Actions.JUMP_TO_ACTION:
                    if (action.state) {
                        var actionState = JSON.parse(action.state);
                        if (actionState && actionState.__devTools) {
                            // Track that we're "debugging" with the devtools so the state/action doesn't get sent back to the devtools
                            actionState.__devTools.debugging = true;
                            // If we have a route then navigate to it
                            if (actionState.__devTools.router) {
                                this.navigateToPath(actionState);
                            }
                            this.setStateFromDevTools(actionState, actionState.__devTools.action + " [" + Actions.REDUX_DEVTOOLS_JUMP + "]");
                        }
                    }
                    break;
                case Actions.IMPORT_STATE:
                    this.loadState(action);
                    break;
            }
        }
    };
    ReduxDevToolsExtension.prototype.loadState = function (action) {
        // clear existing state from devtools
        this.disconnect();
        this.connect();
        if (action.payload) {
            var nextLiftedState = action.payload.nextLiftedState;
            if (nextLiftedState && nextLiftedState.computedStates) {
                nextLiftedState.computedStates.shift();
                for (var _i = 0, _a = nextLiftedState.computedStates; _i < _a.length; _i++) {
                    var computedState = _a[_i];
                    if (computedState.state && computedState.state.__devTools) {
                        this.devToolsExtensionConnection.send(computedState.state.__devTools.action, computedState.state);
                    }
                }
            }
        }
    };
    ReduxDevToolsExtension.prototype.navigateToPath = function (actionState) {
        var path = actionState.__devTools.router.path;
        if (window.location.pathname !== path) {
            // Ensure route info doesn't make it into the devtool
            // since the devtool is actually triggering the route
            // rather than an end user interacting with the app.
            // It will be set to false in this.hookRouter().
            this.routeTriggeredByDevTools = true;
            if (this.config && this.config.customRouteNavigator) {
                this.config.customRouteNavigator.navigate(path);
                return;
            }
            if (this.checkIsAngular()) {
                this.angularExtension.navigate(path);
                return;
            }
            if (this.isReact && (this.config && this.config.reactRouterHistory)) {
                this.config.reactRouterHistory.push(path);
                return;
            }
        }
    };
    ReduxDevToolsExtension.prototype.setStateFromDevTools = function (state, action) {
        var _this = this;
        // #### Run in Angular zone if it's loaded to help with change dectection
        if (this.angularExtension) {
            this.angularExtension.runInZone(function () { return _this.dispatchDevToolsState(state, action); });
            return;
        }
        this.dispatchDevToolsState(state, action);
    };
    ReduxDevToolsExtension.prototype.dispatchDevToolsState = function (state, action) {
        // Set devtools state for each service but don't dispatch state
        // since it will also dispatch global state by default when setState() is called
        for (var _i = 0, _a = observable_store_1.ObservableStore.allStoreServices; _i < _a.length; _i++) {
            var service = _a[_i];
            service.setState(state, action, false);
            // dispatch service state but not global state
            service.dispatchState(state, false);
        }
        // dispatch global state changes
        this.dispatchState(state);
    };
    ReduxDevToolsExtension.prototype.sync = function () {
        var _this = this;
        this.globalStateChanged.subscribe(function (state) {
            if (_this.devToolsExtensionConnection) {
                // See if we're debugging (time travel or jump actions) using the redux devtools
                // If we're debugging then don't send the state back to the devtools or it'll be duplicated
                if (state && state.__devTools && state.__devTools.debugging) {
                    // delete debugging property to avoid clutter in __devTools property
                    delete state.__devTools.debugging;
                }
                else {
                    _this.sendStateToDevTool();
                }
            }
        });
    };
    ReduxDevToolsExtension.prototype.sendStateToDevTool = function () {
        if (this.stateHistory && this.stateHistory.length) {
            var lastItem = this.stateHistory[this.stateHistory.length - 1];
            var action = lastItem.action, endState = lastItem.endState;
            if (!action.endsWith(Actions.REDUX_DEVTOOLS_JUMP + ']')) {
                // Adding action value here since there's no way to retrieve it when
                // it's dispatched from the redux devtools
                this.devToolsExtensionConnection.send(action, __assign(__assign({}, endState), { __devTools: __assign(__assign({}, endState.__devTools), { action: action }) }));
            }
        }
    };
    ReduxDevToolsExtension.prototype.hookRouter = function () {
        var _this = this;
        try {
            var path = window.location.pathname;
            this.setState({
                __devTools: {
                    router: { path: path },
                    action: Actions.ROUTE_NAVIGATION
                }
            }, Actions.ROUTE_NAVIGATION + " [" + path + "]");
            window.history.pushState = (function (f) { return function () {
                var ret = f.apply(this, arguments);
                window.dispatchEvent(new CustomEvent('pushstate', { detail: window.location.pathname }));
                window.dispatchEvent(new CustomEvent('locationchange', { detail: window.location.pathname }));
                return ret;
            }; })(window.history.pushState);
            window.history.replaceState = (function (f) { return function () {
                var ret = f.apply(this, arguments);
                window.dispatchEvent(new CustomEvent('replacestate', { detail: window.location.pathname }));
                window.dispatchEvent(new CustomEvent('locationchange', { detail: window.location.pathname }));
                return ret;
            }; })(window.history.replaceState);
            window.addEventListener('popstate', function () {
                window.dispatchEvent(new CustomEvent('locationchange', { detail: window.location.pathname }));
            });
            window.addEventListener('locationchange', function (e) {
                if (!_this.routeTriggeredByDevTools) {
                    var path_1 = e.detail;
                    _this.setState({
                        __devTools: {
                            router: { path: path_1 },
                            action: Actions.ROUTE_NAVIGATION
                        }
                    }, Actions.ROUTE_NAVIGATION + " [" + path_1 + "]");
                }
                else {
                    _this.routeTriggeredByDevTools = false;
                }
            });
        }
        catch (e) {
            console.log(e);
        }
    };
    ReduxDevToolsExtension.prototype.checkIsReact = function () {
        var isReact = (this.window.__REACT_DEVTOOLS_GLOBAL_HOOK__ &&
            this.window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers &&
            this.window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers.length) ||
            this.window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__ || this.window.React ||
            (this.window.require && (this.require('react') || this.require('React')));
        return isReact;
    };
    ReduxDevToolsExtension.prototype.checkIsAngular = function () {
        return this.window.ng || this.window.getAllAngularTestabilities;
    };
    return ReduxDevToolsExtension;
}(observable_store_1.ObservableStore));
exports.ReduxDevToolsExtension = ReduxDevToolsExtension;
var Actions;
(function (Actions) {
    Actions["DISPATCH"] = "DISPATCH";
    Actions["JUMP_TO_STATE"] = "JUMP_TO_STATE";
    Actions["JUMP_TO_ACTION"] = "JUMP_TO_ACTION";
    Actions["REDUX_DEVTOOLS_JUMP"] = "REDUX_DEVTOOLS_JUMP";
    Actions["ROUTE_NAVIGATION"] = "ROUTE_NAVIGATION";
    Actions["IMPORT_STATE"] = "IMPORT_STATE";
})(Actions || (Actions = {}));
