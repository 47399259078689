import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MercatusToastyService} from '../services/mercatus-toasty.service';

@Injectable()
export class HttpSuccessToastInterceptor implements HttpInterceptor {
    private methodsToSendSuccessMessage = ['POST', 'PUT', 'PATCH', 'DELETE'];
    private defaultMessage = 'Änderungen gespeichert.'
    private endpointMessageMapping = [
        {endpointEndsWith: '/download', message: 'Download gestartet.'},
    ];

    private excludingEndpoints = [ '/pdfpreview/', '/generate-accounts/' ];

    constructor(private mercatusToastyService: MercatusToastyService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                tap((response: HttpEvent<any>) => {
                        if (response instanceof HttpResponse) {
                            this.processRequest(request, response);
                        }
                    }
                )
            );
    }

    private processRequest(request: HttpRequest<any>, response: HttpResponse<any>) {
        if (response.status >= 200 && response.status <= 300 &&
            this.methodsToSendSuccessMessage.includes(request.method) &&
            this.excludingEndpoints.every(e => !request.url.includes(e))) {
            const message = this.endpointMessageMapping.find(e => request.url.endsWith(e.endpointEndsWith))?.message ?? this.defaultMessage;
            this.mercatusToastyService.showSuccessToast(message);
        }
    }
}
