import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {AppModule} from './app.module';
import {AuthGuard} from "./auth.guard";

const routes: Routes = [
    {path: 'dashboard', loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
    {path: 'veranstaltungen', loadChildren: () => import('app/veranstaltungen/veranstaltungen.module').then(m => m.VeranstaltungenModule), canActivate: [AuthGuard]},
    {path: 'kontakte', loadChildren: () => import('app/kontakte/kontakte.module').then(m => m.KontakteModule), canActivate: [AuthGuard]},
    {path: 'settings', loadChildren: () => import('app/settings/app-settings.module').then(m => m.AppSettingsModule), canActivate: [AuthGuard]},
    {path: '', loadChildren: () => import('app/authentication/authentication.module').then(m => m.AuthenticationModule)},
    {path: 'login', loadChildren: () => import('app/authentication/authentication.module').then(m => m.AuthenticationModule)},
    {path: 'admin', loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},
    {path: '**', redirectTo: '', pathMatch: 'full'}
];

export const ModuleRouting: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, {});
