import {KeycloakAuthGuard, KeycloakService} from "keycloak-angular";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected router: Router,
        protected keycloakAngular: KeycloakService
    ) {
        super(router, keycloakAngular);
    }

    async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (!this.authenticated) {
            await this.keycloakAngular.login();
            return false;
        }
        return true;
    }
}