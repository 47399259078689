import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MercatusToastyService } from '../services/mercatus-toasty.service';

@Injectable()
export class HttpErrorReportingInterceptor implements HttpInterceptor {

    constructor(private mercatusToastyService: MercatusToastyService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const reqClone = this.actBeforeRequest(request);
        return next.handle(reqClone)
            .pipe(
                tap((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            this.actAfterResponse(event);
                        }
                    }, (error: any) => {
                        if (error instanceof HttpErrorResponse) {
                            this.actOnErrors(error);
                        }
                    }
                )
            );

    }

    // Before Request, After Response and Error Handling
    private actBeforeRequest(request: HttpRequest<any>): HttpRequest<any> {
        return request;
    }

    private actAfterResponse(response: HttpResponse<any>) {
        // Nothing to do here atm
    }

    private actOnErrors(error: HttpErrorResponse) {
        // HttpErrorReportingInterceptor.logErrorToConsole(error);
        this.showErrorToast(error);
    }


    // Helpers
    private showErrorToast(error: any) {
        let errorMessage = error.error;
        const errorStatus = error.status ? ` (${error.status})` : '';
        if (errorMessage instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function () {
                // noinspection JSPotentiallyInvalidUsageOfClassThis
                this.mercatusToastyService.showErrorToast(`${reader.result}${errorStatus}`);
            }.bind(this);
            reader.readAsText(error.error);
        } else {

            if (errorMessage instanceof ProgressEvent) {
                errorMessage = 'Fehler beim Laden der Daten.';
            } else if (error.status === 401) {
                errorMessage = 'Fehler bei der Überprüfung Ihrer Benutzderdaten. Bitte melden Sie sich ab und danach wieder an.';
            } else if (typeof errorMessage === 'object') {
                errorMessage = 'Fehler beim Laden der Daten.';
            }

            this.mercatusToastyService.showErrorToast(`${errorMessage.replace(/"/g, '')}${errorStatus}`);
        }

    }


    private static logErrorToConsole(error: any) {
        const consoleErrorMessage = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText} - ${error._body}` : 'Server error';
        console.error(error);
        console.error(consoleErrorMessage);
    }

}
